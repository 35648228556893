body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: auto;
  }
  .aws,
  .linkedin {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .logo {
    width: 100px;
  }
  .msgun {
    margin-top: 200px;
    width: 200px;
    height: 1000px;
    border-radius: 50%;
    object-fit:fill; /* Ensures the image covers the entire area */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 

  }
  
  #header {
    background: #f5f5f5;
    padding: 20px 0;
  }
  
  #header nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  #header ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  #header ul li {
    margin-left: 20px;
  }
  
  #header ul li a {
    text-decoration: none;
    color: #333;
  }
  
  #header .fa-bars, #header .fa-times {
    display: none;
    cursor: pointer;
  }
  
  .header-text {
    text-align: center;
    padding: 50px 20px;
  }
  
  .header-text h1 {
    font-size: 2.5em;
    margin: 10px 0;
  }
  
  .header-text p {
    font-size: 1.2em;
    color: #666;
  }
  
  .header-text span {
    color: #ff6347;
  }
  
  #about, #services, #projects, #contact {
    padding: 50px 0;
  }
  
  .sub-title {
    font-size: 2em;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
  }
  
  .sub-title::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background: #ff6347;
    margin: 10px auto;
  }
  
  .about-col-1 img, .work img {
    width: 100%;
  }
  
  .about-col-2 {
    padding: 20px;
  }
  
  .tab-titles {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .tab-links {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ddd;
    flex: 1;
    text-align: center;
    margin-right: 5px;
  }
  
  .tab-links:last-child {
    margin-right: 0;
  }
  
  .active-link {
    background: #ff6347;
    color: #fff;
    border: none;
  }
  
  .tab-contents {
    display: none;
    margin-top: 20px;
  }
  
  .active-tab {
    display: block;
  }
  
  .vertical-skills {
    list-style: none;
    padding: 0;
  }
  
  .vertical-skills li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  #percent {
    color: #ff6347;
  }
  
  .services-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .services-list > div {
    flex: 1;
    margin: 20px;
    text-align: center;
  }
  
  .services-list i {
    font-size: 2em;
    color: #ff6347;
    margin-bottom: 10px;
  }
  
  .work-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .work {
    position: relative;
    flex: 1;
    margin: 20px;
  }
  
  .work img {
    width: 100%;
  }
  
  .layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .work:hover .layer {
    opacity: 1;
  }
  
  .layer h3 {
    font-size: 1.5em;
    margin: 10px 0;
  }
  
  .layer p {
    text-align: center;
    padding: 0 20px;
  }
  
  .layer a {
    color: #ff6347;
    margin-top: 10px;
    font-size: 1.2em;
  }
  
  .contact-left {
    flex: 1;
    padding: 20px;
  }
  
  .contact-left p, .contact-right form input, .contact-right form textarea {
    margin-bottom: 20px;
  }
  
  .social-icons a {
    margin-right: 10px;
    font-size: 1.5em;
    color: #333;
  }
  
  .social-icons a:hover {
    color: #ff6347;
  }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    background: #ff6347;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .btn2 {
    background: #333;
  }
  
  .contact-right {
    flex: 1;
    padding: 20px;
  }
  
  .contact-right form input, .contact-right form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .copyright {
    text-align: center;
    padding: 20px 0;
    background: #f5f5f5;
    margin-top: 50px;
  }
  
  @media (max-width: 768px) {
    .services-list, .work-list {
      flex-direction: column;
    }
  
    #header .fa-bars, #header .fa-times {
      display: block;
    }
  
    #sidemenu {
      position: fixed;
      right: -200px;
      top: 0;
      width: 200px;
      height: 100%;
      background: #333;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: right 0.3s;
    }
  
    #sidemenu ul {
      flex-direction: column;
      align-items: center;
    }
  
    #sidemenu ul li {
      margin: 20px 0;
    }
  
    #sidemenu ul li a {
      color: #fff;
    }
  }
  